<template>
  <div class="card">
    <div class="card-body">
      <h3 class="card-title">Registrati</h3>
      <div
        :class="['alert', submitResult ? 'alert-success' : 'alert-danger']"
        v-if="isSubmitted"
      >
        {{ axiosMessage }}
      </div>
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="Step 1"></el-step>
        <el-step title="Step 2"></el-step>
        <el-step title="Step 3"></el-step>
        <el-step title="Step 4"></el-step>
        
      </el-steps>
      <step-one
        @stepOne="handleOne($event)"
        v-if="active == 0"
        :formValuesPreviousStep="formValues"
        :errors="errorsStepOne"
      ></step-one>
      <step-two
        :errors="errorsStepTwo"
        :formValuesPreviousStep="formValues"
        @stepTwo="handleTwo($event)"
        @stepTwoInsertCompany="handleTwoInsertCompany($event)"
        @previousStep="previousStep($event)"
        v-if="active == 1"
      ></step-two>
      <step-three
        :errors="errorsStepThree"
        :formValuesPreviousStep="formValues"
        @stepThree="handleThree($event)"
        @previousStep="previousStep($event)"
        v-if="active == 2"
      ></step-three>
      <step-four
        :changed="changed"
        :errors="errorsStepFour"
        :formValuesPreviousStep="formValues"
        @stepFour="handleFour($event)"
        @previousStep="previousStep($event)"
        v-if="active == 3"
      ></step-four>
      <step-five v-if="active == 4" :formValues="formValues"></step-five
      ><!--<el-step title="Step 5"></el-step>-->
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import moment from "moment";
import StepOne from "./Register/StepOne.vue";
import StepTwo from "./Register/StepTwo.vue";
import StepThree from "./Register/StepThree.vue";
import StepFour from "./Register/StepFour.vue";
import StepFive from "./Register/StepFive.vue";
import { Register } from "../api/login";
export default {
  components: { StepOne, StepTwo, StepThree,StepFive,  StepFour },//
  data() {
    return {
      active: 0,
      stepStack: [],
      formValues: {},
      isSubmitted: false,
      submitResult: false,
      axiosMessage: "",
      company: false,
      changed: 0,
      errorsStepOne: {
        Nome: "",
        Cognome: "",
        NascitaData: "",
        Nazionalita: "",
        NascitaComune: "",
        Sesso: "",
        CodiceFiscale: "",
      },
      errorsStepTwo: {
        Nazione: "",
        Regione: "",
        Provincia: "",
        Comune: "",
        Indirizzo: "",
        NumeroCivico: "",
        CAP: "",
        Localita: "",
        Edificio: "",
      },
      errorsStepThree: {
        SocietaDenominazione: "",
        SocietaPartitaIVA: "",
        SocietaTipoDitta: "",
        SocietaNumeroREA: "",
      },
      errorsStepFour: {
        Email: "",
        Password: "",
        PasswordConfirm: "",
        Sponsor: "",
      },
    };
  },
  methods: {
    previousStep() {
      this.active = this.stepStack.pop();
    },
    handleOne(value) {
      this.stepStack.push(this.active);
      this.active = 1;

      Object.assign(this.formValues, value);

      this.formValues.NascitaData = moment
        .utc(this.formValues.NascitaData)
        .local()
        .format();
    },
    handleTwoInsertCompany(value) {
      this.stepStack.push(this.active);
      this.active = 2;

      Object.assign(this.formValues, value);
      this.company = true;
    },
    handleTwo(value) {
      this.stepStack.push(this.active);
      this.active = 3;

      Object.assign(this.formValues, value);
    },
    handleThree(value) {
      this.stepStack.push(this.active);
      this.active = 3;

      Object.assign(this.formValues, value);
    },

    async handleFour(value) {
      this.isSubmitted = false;
      this.stepStack.push(this.active);
      // this.active = 4;
      let username = { Username: value.Email };
      if (Cookies.get("referral")) {
        let referral = { ReferralID: Cookies.get("referral") };
        Object.assign(this.formValues, referral);
      } else {
        let referral = { ReferralID: "0" };
        Object.assign(this.formValues, referral);
      }
      Object.assign(this.formValues, value);
      Object.assign(this.formValues, username);
      delete this.formValues["Regione"];
     if (this.formValues.Sponsor===undefined){
       let sponsor = {Sponsor:""}
       Object.assign(this.formValues, sponsor);
     }
      await Register(this.formValues)
        .then(() => {
          this.isSubmitted = true;
          this.submitResult = true;
          this.axiosMessage =
            "Registrazione avvenuta con successo!! Il tuo account al momento non è attivo, ricordati di acquistare la licenza per utilizzare il tuo account.";
        })
        .catch((error) => {
          this.isSubmitted = true;
          this.submitResult = false;
          this.axiosMessage = "Registrazione fallita";
          let activeStep = 3;
          let keys = Object.keys(error.response.data.errors);
          if (keys.indexOf("Nome") !== -1) {
            this.errorsStepOne.Nome.push(error.response.data.errors.Nome[0]);
            if (activeStep < 0) {
              activeStep = 0;
            }
          }
          if (keys.indexOf("Cognome") !== -1) {
            this.errorsStepOne.Cognome.push(
              error.response.data.errors.Cognome[0]
            );
            if (activeStep < 0) {
              activeStep = 0;
            }
          }
          if (keys.indexOf("NascitaData") !== -1) {
            this.errorsStepOne.NascitaData =
              error.response.data.errors.NascitaData[0];

            if (activeStep > 0) {
              activeStep = 0;
            }
          }
          if (keys.indexOf("Nazionalita") !== -1) {
            this.errorsStepOne.Nazionalita =
              error.response.data.errors.Nazionalita[0];

            if (activeStep > 0) {
              activeStep = 0;
            }
          }
          if (keys.indexOf("NascitaComune") !== -1) {
            this.errorsStepOne.NascitaComune =
              error.response.data.errors.NascitaComune[0];

            if (activeStep > 0) {
              activeStep = 0;
            }
          }
          if (keys.indexOf("Sesso") !== -1) {
            this.errorsStepOne.Sesso = error.response.data.errors.Sesso[0];
            if (activeStep > 0) {
              activeStep = 0;
            }
          }
          if (keys.indexOf("CodiceFiscale") !== -1) {
            this.errorsStepOne.CodiceFiscale =
              error.response.data.errors.CodiceFiscale[0];
            if (activeStep > 0) {
              activeStep = 0;
            }
          }
          if (keys.indexOf("Nazione") !== -1) {
            this.errorsStepTwo.Nazione = error.response.data.errors.Nazione[0];
            if (activeStep > 1) {
              activeStep = 1;
            }
          }
          if (keys.indexOf("Regione") !== -1) {
            this.errorsStepTwo.Regione = error.response.data.errors.Regione[0];
            if (activeStep > 1) {
              activeStep = 1;
            }
          }
          if (keys.indexOf("Provincia") !== -1) {
            this.errorsStepTwo.Provincia =
              error.response.data.errors.Provincia[0];
            if (activeStep > 1) {
              activeStep = 1;
            }
          }
          if (keys.indexOf("Comune") !== -1) {
            this.errorsStepTwo.Comune = error.response.data.errors.Comune[0];
            if (activeStep > 1) {
              activeStep = 1;
            }
          }
          if (keys.indexOf("Indirizzo") !== -1) {
            this.errorsStepTwo.Indirizzo =
              error.response.data.errors.Indirizzo[0];
            if (activeStep > 1) {
              activeStep = 1;
            }
          }
          if (keys.indexOf("CAP") !== -1) {
            this.errorsStepTwo.CAP = error.response.data.errors.CAP[0];
            if (activeStep > 1) {
              activeStep = 1;
            }
          }
          if (keys.indexOf("Localita") !== -1) {
            this.errorsStepTwo.Localita =
              error.response.data.errors.Localita[0];
            if (activeStep > 1) {
              activeStep = 1;
            }
          }
          if (keys.indexOf("Edificio") !== -1) {
            this.errorsStepTwo.Edificio =
              error.response.data.errors.Edificio[0];
            if (activeStep > 1) {
              activeStep = 1;
            }
          }
          if (keys.indexOf("NumeroCivico") !== -1) {
            this.errorsStepTwo.NumeroCivico =
              error.response.data.errors.NumeroCivico[0];
            if (activeStep > 1) {
              activeStep = 1;
            }
          }
          if (keys.indexOf("SocietaDenominazione") !== -1) {
            this.errorsStepThree.SocietaDenominazione =
              error.response.data.errors.SocietaDenominazione[0];
            if (activeStep > 2) {
              activeStep = 2;
            }
          }
          if (keys.indexOf("SocietaPartitaIVA") !== -1) {
            this.errorsStepThree.SocietaPartitaIVA =
              error.response.data.errors.SocietaPartitaIVA[0];
            if (activeStep > 2) {
              activeStep = 2;
            }
          }
          if (keys.indexOf("SocietaTipoDitta") !== -1) {
            this.errorsStepThree.SocietaTipoDitta =
              error.response.data.errors.SocietaTipoDitta[0];
            if (activeStep > 2) {
              activeStep = 2;
            }
          }
          if (keys.indexOf("SocietaNumeroREA") !== -1) {
            this.errorsStepThree.SocietaNumeroREA =
              error.response.data.errors.SocietaNumeroREA[0];
            if (activeStep > 2) {
              activeStep = 2;
            }
          }
          if (keys.indexOf("Email") !== -1) {
            this.changed = 1;
            this.errorsStepFour.Email = error.response.data.errors.Email[0];
            if (activeStep > 3) {
              activeStep = 3;
            }
          }
          if (keys.indexOf("Password") !== -1) {
            this.changed = 1;
            this.errorsStepFour.Password =
              error.response.data.errors.Password[0];
            if (activeStep > 3) {
              activeStep = 3;
            }
          }
          if (keys.indexOf("PasswordConfirm") !== -1) {
            this.changed = 1;
            this.errorsStepFour.PasswordConfirm =
              error.response.data.errors.PasswordConfirm[0];
            if (activeStep > 3) {
              activeStep = 3;
            }
          }
          if (keys.indexOf("Sponsor") !== -1) {
            this.changed = 1;
            this.errorsStepFour.Sponsor = error.response.data.errors.Sponsor[0];
            if (activeStep > 3) {
              activeStep = 3;
            }
          }
          this.active = activeStep;
        });
    },
  },
};
</script>

<style>
</style>