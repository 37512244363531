<template>
  <div class="container mt-2">
    <div class="row">
      <div class="col text-center"><h4>Residenza</h4></div>
    </div>
  </div>
  <el-form label-position="top">
    <el-form-item label="Nazione" :error="nazioneField.errors[0]">
      <el-select
        filterable
        placeholder="Seleziona"
        v-model="nazioneField.value"
        name="nazione"
        @change="showRegioneSelect()"
      >
        <el-option
          v-for="item in nations"
          :key="item.pkNazione"
          :label="item.nazione"
          :value="item.siglaISO"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Regione" :error="regioneField.errors[0]">
      <el-select
        filterable
        :disabled="!nazioneSelezionata"
        placeholder="Seleziona"
        v-model="regioneField.value"
        name="regione"
        @change="showProvinciaSelect()"
      >
        <el-option
          v-for="item in regioni"
          :key="item.pkRegione"
          :label="item.regione"
          :value="item.pkRegione"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Provincia" :error="provinciaField.errors[0]">
      <el-select
        filterable
        :disabled="!regioneSelezionata"
        placeholder="Seleziona"
        v-model="provinciaField.value"
        name="provincia"
        @change="showComuneSelect()"
      >
        <el-option
          v-for="item in province"
          :key="item.pkProvincia"
          :label="item.provincia"
          :value="item.sigla"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Comune" :error="comuneField.errors[0]">
      <el-select
        filterable
        :disabled="!provinciaSelezionata"
        placeholder="Seleziona"
        v-model="comuneField.value"
        name="comune"
        @change="showCAP()"
      >
        <el-option
          v-for="item in comuni"
          :key="item.pkComune"
          :label="item.comune"
          :value="item.comune"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="CAP" :error="CAPField.errors[0]">
      <el-input
        :disabled="!comuneSelezionato"
        :model-value="CAPField.value"
        placeholder="CAP"
        name="CAP"
        type="text"
        @update:model-value="CAPField.handleChange"
      />
    </el-form-item>
    <el-form-item
      label="Indirizzo"
      :error="indirizzoField.errors[0]"
    >
      <el-input
        :model-value="indirizzoField.value"
        placeholder="Indirizzo"
        name="indirizzo"
        type="text"
        @update:model-value="indirizzoField.handleChange"
    /></el-form-item>
    <el-form-item
      label="Località/Frazione (Opzionale)"
      :error="localitaField.errors[0]"
    >
      <el-input
        :model-value="localitaField.value"
        placeholder="Località/Frazione"
        name="localita"
        type="text"
        @update:model-value="localitaField.handleChange"
      />
    </el-form-item>
    <el-form-item
      label="Numero Civico (Opzionale)"
      :error="numeroCivicoField.errors[0]"
    >
      <el-input
        :model-value="numeroCivicoField.value"
        placeholder="Numero Civico"
        name="numeroCivico"
        type="text"
        @update:model-value="numeroCivicoField.handleChange"
      />
    </el-form-item>

    <el-form-item label="Edificio (Opzionale)" :error="edificioField.errors[0]">
      <el-input
        :model-value="edificioField.value"
        placeholder="Edificio"
        name="edificio"
        type="text"
        @update:model-value="edificioField.handleChange"
      />
    </el-form-item>
    <el-row>
      <el-col
        :xs="24"
        :sm="5"
        :md="6"
        :lg="6"
        :xl="6"
        class="mt-1"
      >
        <el-button type="info" @click="previousStep" >Indietro</el-button>
      </el-col>
      <el-col
        :xs="24"
        :sm="10"
        :md="9"
        :lg="10"
        :xl="11"
        class="mt-1"
      >
        <el-button type="success " @click="insertCompany" 
          >Inserisci Società</el-button
        >
      </el-col>
      <el-col 
        :xs="24"
        :sm="9"
        :md="9"
        :lg="8"
        :xl="7"
        class="mt-1"
      >
        <el-button type="primary" @click="submitForm"
          >Concludi Registrazione</el-button
        >
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapState } from "vuex";
import {
  FetchNations,
  FetchRegioni,
  FetchProvincia,
  FetchComuni,
} from "@/api/fetchData.js";
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  emits: ["stepTwo", "previousStep", "stepTwoInsertCompany"],
  props: ["formValuesPreviousStep", "errors"],
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const nazioneField = reactive(
      useField("Nazione", yup.string().required("Campo richiesto"))
    );
    const regioneField = reactive(useField("Regione"));
    const provinciaField = reactive(useField("Provincia"));
    const comuneField = reactive(useField("Comune"));
    const indirizzoField = reactive(useField("Indirizzo"),yup.string().required("Campo richiesto"));
    const numeroCivicoField = reactive(
      useField("NumeroCivico"),
    );
    const CAPField = reactive(useField("CAP"));
    const localitaField = reactive(useField("Localita"));
    const edificioField = reactive(useField("Edificio"));

    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      nazioneField,
      regioneField,
      provinciaField,
      comuneField,
      indirizzoField,
      CAPField,
      localitaField,
      edificioField,
      numeroCivicoField,
    };
  },
  data() {
    return {
      submitForm: this.handleSubmit(this.handleStepTwo),
      insertCompany: this.handleSubmit(this.handleStepTwoInsertCompany),
      fetchNationsError: false,
      nations: [],
      regioni: [],
      province: [],
      comuni: [],
      nazioneSelezionata: false,
      regioneSelezionata: false,
      provinciaSelezionata: false,
      comuneSelezionato: false,
      provincia: null,
    };
  },
  created() {
    this.fetchNations();
    this.initForm();
  },

  methods: {
    showCAP() {
      if (this.comuneField.value != "") {
        this.CAPField.resetField();
        this.comuneSelezionato = true;
      } else {
        this.provinciaSelezionata = false;
        this.CAPField.resetField();
      }
    },
    async showRegioneSelect() {
      if (this.nazioneField.value == "ITA") {
        this.nazioneSelezionata = true;
        await FetchRegioni().then((response) => {
          this.regioni = response.data;
        });
      } else {
        this.comuneSelezionato = false;
        this.nazioneSelezionata = false;
        this.regioneSelezionata = false;
        this.provinciaSelezionata = false;
        this.comuneField.resetField();
        this.provinciaField.resetField();
        this.regioneField.resetField();
        this.CAPField.resetField();
      }
    },

    async showProvinciaSelect() {
      if (this.regioneField.value != "") {
        this.regioneSelezionata = true;
        this.provinciaSelezionata = false;
        this.comuneSelezionato = false;
        this.provinciaField.resetField();
        this.comuneField.resetField();
        this.CAPField.resetField();
        await FetchProvincia(this.regioneField.value).then((response) => {
          this.province = response.data;
        });
      } else {
        this.regioneSelezionata = false;
        this.provinciaSelezionata = false;
        this.comuneSelezionato = false;
        this.comuneField.resetField();
        this.provinciaField.resetField();
        this.CAPField.resetField();
      }
    },

    async showComuneSelect() {
      if (this.provinciaField.value != "") {
        this.comuneSelezionato = false;
        this.CAPField.resetField();
        this.comuneField.resetField();
        this.provinciaSelezionata = true;
        await FetchComuni(
          this.regioneField.value,
          this.provinciaField.value
        ).then((response) => {
          this.comuni = response.data;
        });
      } else {
        this.provinciaSelezionata = false;
        this.comuneField.resetField();
        this.CAPField.resetField();
        this.comuneSelezionato = false;
      }
    },

    initForm() {
      if (this.errors.Nazione != "") {
        this.nazioneField.errors.push(this.errors.Nazione);
      } else {
        this.nazioneField.value = this.formValuesPreviousStep.Nazione;
      }
      if (this.errors.Regione != "") {
        this.regioneField.errors.push(this.errors.Regione);
      } else {
        this.regioneField.value = this.formValuesPreviousStep.Regione;
      }
      if (this.errors.Regione != "") {
        this.regioneField.errors.push(this.errors.Regione);
      } else {
        this.regioneField.value = this.formValuesPreviousStep.Regione;
      }
      if (this.errors.Provincia != "") {
        this.provinciaField.errors.push(this.errors.Provincia);
      } else {
        this.provinciaField.value = this.formValuesPreviousStep.Provincia;
      }
      if (this.errors.Comune != "") {
        this.comuneField.errors.push(this.errors.Comune);
      } else {
        this.comuneField.value = this.formValuesPreviousStep.Comune;
      }
      if (this.errors.Indirizzo != "") {
        this.indirizzoField.errors.push(this.errors.Indirizzo);
      } else {
        this.indirizzoField.value = this.formValuesPreviousStep.Indirizzo;
      }
      if (this.errors.CAP != "") {
        this.CAPField.errors.push(this.errors.CAP);
      } else {
        this.CAPField.value = this.formValuesPreviousStep.CAP;
      }
      if (this.errors.Localita != "") {
        this.localitaField.errors.push(this.errors.Localita);
      } else {
        this.localitaField.value = this.formValuesPreviousStep.Localita;
      }
      if (this.errors.Edificio != "") {
        this.edificioField.errors.push(this.errors.Edificio);
      } else {
        this.edificioField.value = this.formValuesPreviousStep.Edificio;
      }
      if (this.errors.NumeroCivico != "") {
        this.numeroCivicoField.errors.push(this.errors.NumeroCivico);
      } else {
        this.numeroCivicoField.value = this.formValuesPreviousStep.NumeroCivico;
      }

      if (this.formValuesPreviousStep.Nazione == "ITA") {
        this.nazioneSelezionata = true;
        this.regioneSelezionata = true;
        this.provinciaSelezionata = true;
      }
    },
    async fetchNations() {
      await FetchNations()
        .then((response) => {
          this.nations = response.data;
        })
        .catch(() => {
          this.fetchNationsError = true;
        });
    },
    previousStep() {
      this.$emit("previousStep", this.formValues);
    },
    handleStepTwoInsertCompany(formValues) {
      this.$emit("stepTwoInsertCompany", formValues);
    },
    handleStepTwo(formValues) {
      this.$emit("stepTwo", formValues);
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>