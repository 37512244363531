<template>
  <div class="container mt-2">
    <div class="row">
      <div class="col text-center"><h4>Società</h4></div>
    </div>
  </div>
  <el-form label-position="top">
    <el-form-item label="Nome Società" :error="nomeSocietaField.errors[0]">
      <el-input
        type="text"
        placeholder="Nome Società"
        name="SocietaDenominazione"
        :model-value="nomeSocietaField.value"
        @update:model-value="nomeSocietaField.handleChange"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="Partita IVA" :error="partitaIVAField.errors[0]">
      <el-input
        type="text"
        placeholder="Partita IVA"
        name="SocietaPartitaIVA"
        :model-value="partitaIVAField.value"
        @update:model-value="partitaIVAField.handleChange"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="Tipo Ditta" :error="tipoDittaField.errors[0]">
      <el-select
        filterable
        placeholder="Seleziona"
        v-model="tipoDittaField.value"
        name="SocietaTipoDitta"
      >
        <el-option
          v-for="item in tipoDitta"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Numero REA" :error="numeroREAField.errors[0]">
      <el-input
        type="text"
        placeholder="Numero REA"
        name="SocietaNumeroREA"
        :model-value="numeroREAField.value"
        @update:model-value="numeroREAField.handleChange"
      >
      </el-input>
    </el-form-item>
    <el-row>
      <el-col class="text-start" :span="12">
        <el-button type="info" @click="previousStep">Indietro</el-button>
      </el-col>
      <el-col class="text-end" :span="12">
        <el-button type="primary" @click="submitForm">Avanti</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  emits: ["stepThree", "previousStep"],
  props: ["formValuesPreviousStep", "errors"],
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const nomeSocietaField = reactive(
      useField("SocietaDenominazione", yup.string().required("Campo richiesto"))
    );
    const partitaIVAField = reactive(
      useField(
        "SocietaPartitaIVA",
        yup
          .string()
          .required("Campo richiesto")
          .min(11, "La partita IVA deve essere composta da 11 caratteri")
          .max(11, "La partita IVA deve essere composta da 11 caratteri")
      )
    );
    const tipoDittaField = reactive(
      useField("SocietaTipoDitta", yup.string().required("Campo richiesto"))
    );
    const numeroREAField = reactive(
      useField("SocietaNumeroREA", yup.string().required("Campo richiesto"))
    );

    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      nomeSocietaField,
      partitaIVAField,
      tipoDittaField,
      numeroREAField,
    };
  },
  data() {
    return {
      tipoDitta: [
        {
          value: "1",
          label: "Individuale",
        },
        {
          value: "2",
          label: "SRL",
        },
        {
          value: "3",
          label: "SNC",
        },
        {
          value: "4",
          label: "SPA",
        },
      ],

      submitForm: this.handleSubmit(this.handleStepThree),
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      if (this.errors.SocietaDenominazione != "") {
        this.nomeSocietaField.errors.push(this.errors.SocietaDenominazione);
      } else {
        this.nomeSocietaField.value =
          this.formValuesPreviousStep.SocietaDenominazione;
      }
      if (this.errors.SocietaPartitaIVA != "") {
        this.partitaIVAField.errors.push(this.errors.SocietaPartitaIVA);
      } else {
        this.partitaIVAField.value =
          this.formValuesPreviousStep.SocietaPartitaIVA;
      }

      if (this.errors.SocietaTipoDitta != "") {
        this.tipoDittaField.errors.push(this.errors.SocietaTipoDitta);
      } else {
        this.tipoDittaField.value =
          this.formValuesPreviousStep.SocietaTipoDitta;
      }
      if (this.errors.SocietaNumeroREA != "") {
        this.numeroREAField.errors.push(this.errors.SocietaNumeroREA);
      } else {
        this.numeroREAField.value =
          this.formValuesPreviousStep.SocietaNumeroREA;
      }
    },
    handleStepThree(formValues) {
      this.$emit("stepThree", formValues);
    },
    previousStep() {
      this.$emit("previousStep");
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>