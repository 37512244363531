<template>
  <!-- "https://euforya.it/checkout/?pacchetto=LicenzaBase"-->
  <div class="container-fluid">
    <el-row :gutter="3">
      <el-col :span="8">
        <el-card shadow="hover" :body-style="{ padding: '0px' }">
          <template #header>
            <p class="text-center fs-4 fw-bold">Licenza Amanthy personale</p>
          </template>
          <img
            src="../../assets/images/Kit/licenzaPersonale.jpeg"
            class="image"
          />
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover" :body-style="{ padding: '0px' }">
          <template #header>
            <p class="text-center fs-4 fw-bold">Pacchetto Start</p>
          </template>
          <img src="../../assets/images/Kit/licenzaStart.jpeg" class="image" />
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover" :body-style="{ padding: '0px' }">
          <template #header>
            <p class="text-center fs-4 fw-bold">Pacchetto Top</p>
          </template>
          <img src="../../assets/images/Kit/licenzaTop.jpeg" class="image" />
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <form
          action="http://localhost/Progetti%20Wordpress/mlmfrontend/?page_id=8&pacchetto=LicenzaBase"
          method="POST"
        >
          <input
            type="hidden"
            name="firstName"
            id="firstName"
            v-model="firstName"
          />
          <input
            type="hidden"
            name="lastName"
            id="lastName"
            v-model="lastName"
          />
          <input type="hidden" name="nazione" id="nazione" v-model="nazione" />
          <input type="hidden" name="regione" id="regione" v-model="regione" />
          <input
            type="hidden"
            name="provincia"
            id="provincia"
            v-model="provincia"
          />
          <input type="hidden" name="comune" id="comune" v-model="comune" />
          <input
            type="hidden"
            name="indirizzo"
            id="indirizzo"
            v-model="indirizzo"
          />
          <input type="hidden" name="CAP" id="CAP" v-model="CAP" />
          <input
            type="hidden"
            name="localita"
            id="localita"
            v-model="localita"
          />
          <input
            type="hidden"
            name="edificio"
            id="edificio"
            v-model="edificio"
          />
          <input
            type="hidden"
            name="numeroCivico"
            id="numeroCivico"
            v-model="numeroCivico"
          />
          <input
            type="hidden"
            name="nomeSocieta"
            id="nomeSocieta"
            v-model="nomeSocieta"
          />
          <input type="hidden" name="email" id="email" v-model="email" />
          <input
            type="hidden"
            name="username"
            id="username"
            v-model="username"
          />
          <el-button type="warning" native-type="submit">
            Licenza Regular</el-button
          >
        </form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["formValues"],
  data() {
    return {
      firstName: "",
      lastName: "",
      dataNascita: "",
      comuneNascita: "",
      codiceFiscale: "",
      nazione: "",
      provincia: "",
      comune: "",
      indirizzo: "",
      CAP: "",
      localita: "",
      edificio: "",
      numeroCivico: "",
      nomeSocieta: "",
      partitaIVA: "",
      tipoDitta: "",
      numeroREA: "",
      provenienza: "",
      email: "",
      password: "",
      passwordConfirm: "",
      username: "",
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.firstName = this.formValues.Nome;
      this.lastName = this.formValues.Cognome;
      this.dataNascita = this.formValues.NascitaData;
      this.comuneNascita = this.formValues.NascitaComune;
      this.codiceFiscale = this.formValues.CodiceFiscale;
      this.nazione = this.formValues.Nazione;
      this.provincia = this.formValues.Provincia;
      this.comune = this.formValues.Comune;
      this.indirizzo = this.formValues.Indirizzo;
      this.CAP = this.formValues.CAP;
      this.localita = this.formValues.Localita;
      this.edificio = this.formValues.Edificio;
      this.numeroCivico = this.formValues.NumeroCivico;
      this.nomeSocieta = this.formValues.SocietaDenominazione;
      this.partitaIVA = this.formValues.SocietaPartitaIVA;
      this.tipoDitta = this.formValues.SocietaTipoDitta;
      this.numeroREA = this.formValues.SocietaNumeroREA;
      this.email = this.formValues.Email;
    },
  },
};
</script>

<style scoped>
.image {
  width: 60%;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
</style>