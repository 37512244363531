 <template>
  <main class="d-flex w-100">
    <div class="container d-flex flex-column">
      <div class="row mt-5">
        <div class="col-sm-10 col-md-10 col-lg-7 mx-auto d-table h-100 mt-5">
          <div class="d-table-cell">
            <div class="text-center mt-5">
              <Register />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import Register from "../components/RegisterWithSteps.vue";
export default {
  components: { Register },
};
</script>

<style>
</style>