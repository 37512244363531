<template>
  <div class="container mt-2">
    <div class="row">
      <div class="col text-center"><h4>Informazioni Login</h4></div>
    </div>
  </div>
  <el-form label-position="top">
    <el-form-item label="Email" :error="emailField.errors[0]">
      <el-input
        type="text"
        placeholder="Email"
        name="Email"
        :model-value="emailField.value"
        @update:model-value="emailField.handleChange"
      >
      </el-input>
    </el-form-item>

    <el-tooltip
      v-model="capsTooltipPassword"
      content="Caps lock is On"
      placement="right"
      manual
    >
      <el-form-item label="Password" :error="passwordField.errors[0]">
        <el-input
          :key="passwordType"
          :type="passwordType"
          :model-value="passwordField.value"
          @update:model-value="passwordField.handleChange"
          @keyup="checkCapslockPassword"
          @blur="capsTooltipPassword = false"
          ref="password"
          placeholder="Password"
          name="Password"
        />
        <span class="show-pwd" @click="showPwd">
          <font-awesome-icon
            :icon="passwordType === 'password' ? eye : eyeClosed"
          />
        </span>
      </el-form-item>
    </el-tooltip>
    <el-tooltip
      v-model="capsTooltipPasswordConfirm"
      content="Caps lock is On"
      placement="right"
      manual
    >
      <el-form-item
        label="Conferma Password"
        :error="passwordConfirmField.errors[0]"
      >
        <el-input
          ref="passwordConfirm"
          :key="confirmPasswordType"
          :type="confirmPasswordType"
          :model-value="passwordConfirmField.value"
          @update:model-value="passwordConfirmField.handleChange"
          @keyup="checkCapslockPasswordConfirm"
          @blur="capsTooltipPasswordConfirm = false"
          placeholder="Conferma Password"
          name="PasswordConfirm"
        />
        <span class="show-pwd" @click="showConfirmPwd">
          <font-awesome-icon
            :icon="confirmPasswordType === 'password' ? eye : eyeClosed"
          />
        </span>
      </el-form-item>
    </el-tooltip>
    <el-form-item label="Sponsor" :error="sponsorField.errors[0]">
      <el-input
        type="text"
        placeholder="Sponsor"
        name="Sponsor"
        :model-value="sponsorField.value"
        @update:model-value="sponsorField.handleChange"
      >
      </el-input>
    </el-form-item>
    <el-row>
      <el-col class="text-start" :span="12">
        <el-button type="info" @click="previousStep">Indietro</el-button>
      </el-col>
      <el-col class="text-end" :span="12">
        <el-button type="primary" @click="submitForm">Conferma</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
export default {
  emits: ["stepFour", "previousStep"],
  props: ["formValuesPreviousStep", "errors", "changed"],
  components: { FontAwesomeIcon },
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();

    const emailField = reactive(
      useField(
        "Email",
        yup
          .string()
          .required("Campo richiesto")
          .email("Inserire un'email valida")
      )
    );
    const passwordField = reactive(
      useField(
        "Password",
        yup.string().required("Campo richiesto").min(8, "Minimo 8 caratteri")
      )
    );
    const passwordConfirmField = reactive(
      useField(
        "PasswordConfirm",
        yup
          .string()
          .required("Campo richiesto")
          .test(
            "passwords-match",
            "Le password devono corrispondere",
            function (value) {
              return passwordField.value === value;
            }
          )
      )
    );
    const sponsorField = reactive(useField("Sponsor"));
    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      emailField,
      passwordField,
      passwordConfirmField,
      sponsorField,
    };
  },
  data() {
    return {
      submitForm: this.handleSubmit(this.handleStepFour),
      passwordType: "password",
      confirmPasswordType: "password",
      eye: faEye,
      eyeClosed: faEyeSlash,
      capsTooltipPassword: false,
      capsTooltipPasswordConfirm: false,
    };
  },
  created() {
    this.initForm();
  },
  watch: {
    changed: function () {
      this.initForm();
    },
  },

  methods: {
    checkCapslockPassword(e) {
      const { key } = e;
      this.capsTooltipPassword =
        key && key.length === 1 && key >= "A" && key <= "Z";
    },
    checkCapslockPasswordConfirm(e) {
      const { key } = e;
      this.capsTooltipPasswordConfirm =
        key && key.length === 1 && key >= "A" && key <= "Z";
    },
    initForm() {
      if (this.errors.Email != "") {
        this.emailField.errors.push(this.errors.Email);
        console.log(this.emailField.errors);
      } else {
        this.emailField.value = this.formValuesPreviousStep.Email;
      }
      if (this.errors.Password != "") {
        this.passwordField.errors.push(this.errors.Password);
      } else {
        this.passwordField.value = this.formValuesPreviousStep.Password;
      }
      if (this.errors.PasswordConfirm != "") {
        this.passwordConfirmField.errors.push(this.errors.PasswordConfirm);
      } else {
        this.passwordConfirmField.value =
          this.formValuesPreviousStep.PasswordConfirm;
      }
      if (this.errors.Sponsor != "") {
        this.sponsorField.errors.push(this.errors.Sponsor);
      } else {
        this.sponsorField.value = this.formValuesPreviousStep.Sponsor;
      }
    },
    showConfirmPwd() {
      if (this.confirmPasswordType === "password") {
        this.confirmPasswordType = "";
      } else {
        this.confirmPasswordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.confirmPasswordType.focus();
      });
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    previousStep() {
      this.$emit("previousStep");
    },
    handleStepFour(formValues) {
      this.$emit("stepFour", formValues);
    },
  },
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.show-pwd {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 22px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
</style>