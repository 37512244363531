<template>
  <div class="container mt-2">
    <div class="row">
      <div class="col text-center"><h4>Informazioni Fiscali</h4></div>
    </div>
  </div>
  <el-form label-position="top">
    <el-form-item label="Nome" :error="nameField.errors[0]">
      <el-input
        type="text"
        placeholder="Nome"
        name="Nome"
        :model-value="nameField.value"
        @update:model-value="nameField.handleChange"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="Cognome" :error="lastNameField.errors[0]">
      <el-input
        type="text"
        placeholder="Cognome"
        name="Cognome"
        :model-value="lastNameField.value"
        @update:model-value="lastNameField.handleChange"
      >
      </el-input>
    </el-form-item>
    <el-form-item
      label="Data di Nascita"
      :error="dataNascitaField.errors[0]"
    >
      <el-date-picker
        style="width: 100%"
        type="date"
        placeholder="gg/mm/aaaa"
        format="DD/MM/YYYY"
        name="NascitaData"
        v-model="dataNascitaField.value"
        @update:v-model="dataNascitaField.handleChange"
      >
        >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="Nazionalità" :error="nazionalitaField.errors[0]">
      <el-select
        filterable
        placeholder="Seleziona"
        v-model="nazionalitaField.value"
        name="Nazionalita"
      >
        <el-option
          v-for="item in nations"
          :key="item.pkNazione"
          :label="item.nazione"
          :value="item.siglaISO"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      label="Comune di Nascita"
      :error="comuneNascitaField.errors[0]"
    >
      <el-input
        type="text"
        placeholder="Comune di Nascita"
        name="NascitaComune"
        :model-value="comuneNascitaField.value"
        @update:model-value="comuneNascitaField.handleChange"
      >
      </el-input>
    </el-form-item>
    <el-form-item
      label="Sesso"
      :error="sessoField.errors[0]"
      style="width: 150px"
    >
      <el-radio-group
        @change="sessoField.handleChange"
        v-model="sessoField.value"
      >
        <el-radio label="F">F</el-radio>
        <el-radio label="M">M</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="Codice Fiscale" :error="codiceFiscaleField.errors[0]">
      <el-input
        type="text"
        placeholder="Codice Fiscale"
        name="CodiceFiscale"
        :model-value="codiceFiscaleField.value"
        @update:model-value="codiceFiscaleField.handleChange"
      >
      </el-input>
    </el-form-item>
    <el-button type="primary" @click="submitForm">Avanti</el-button>
  </el-form>
</template>

<script>
import { FetchNations } from "@/api/fetchData.js";
import { useField, useForm } from "vee-validate";
import { reactive } from "vue";
import * as yup from "yup";
import moment from "moment";
export default {
  emits: ["stepOne"],
  props: ["formValuesPreviousStep", "errors"],
  setup() {
    const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
    const nameField = reactive(
      useField("Nome", yup.string().required("Campo richiesto"))
    );
    const lastNameField = reactive(
      useField("Cognome", yup.string().required("Campo richiesto"))
    );
    const dataNascitaField = reactive(
      useField(
        "NascitaData",
        yup
          .string()
          .required("Campo richiesto")
          .test("minimum-18yo", "Età minima 18 anni", function (value) {
            let currentDate = moment();
            if (moment().diff(moment(value), "years") < 18) {
              return false;
            }
            if (
              moment().diff(moment(value), "month") <
              moment().diff(moment(currentDate), "month")
            ) {
              return false;
            }
            if (
              moment().diff(moment(value), "day") <
              moment().diff(moment(currentDate), "day")
            ) {
              return false;
            }
            return true;
          })
      )
    );
    const nazionalitaField = reactive(
      useField("Nazionalita", yup.string().required("Campo richiesto"))
    );
    const comuneNascitaField = reactive(
      useField("NascitaComune", yup.string().required("Campo richiesto"))
    );
    const sessoField = reactive(
      useField("Sesso", yup.string().required("Campo richiesto"))
    );
    const codiceFiscaleField = reactive(
      useField(
        "CodiceFiscale",
        yup
          .string()
          .required("Campo richiesto")
          .min(16, "Il codice fiscale deve essere composto da 16 caratteri")
          .max(16, "Il codice fiscale deve essere composto da 16 caratteri")
      )
    );
    return {
      formMeta,
      handleSubmit,
      resetForm,
      handleReset,
      nameField,
      lastNameField,
      dataNascitaField,
      comuneNascitaField,
      codiceFiscaleField,
      sessoField,
      nazionalitaField,
    };
  },
  data() {
    return {
      submitForm: this.handleSubmit(this.handleStepOne),
      nations: [],
    };
  },
  created() {
    this.initForm();
    this.fetchNations();
  },

  methods: {
    async fetchNations() {
      await FetchNations()
        .then((response) => {
          this.nations = response.data;
        })
        .catch(() => {
          this.fetchNationsError = true;
        });
    },
    initForm() {
      if (this.errors.CodiceFiscale != "") {
        this.codiceFiscaleField.errors.push(this.errors.CodiceFiscale);
      } else {
        this.codiceFiscaleField.value =
          this.formValuesPreviousStep.CodiceFiscale;
      }
      if (this.errors.Nome != "") {
        this.nameField.errors.push(this.errors.Nome);
      } else {
        this.nameField.value = this.formValuesPreviousStep.Nome;
      }
      if (this.errors.Cognome != "") {
        this.lastNameField.errors.push(this.errors.Cognome);
      } else {
        this.lastNameField.value = this.formValuesPreviousStep.Cognome;
      }
      if (this.errors.NascitaData != "") {
        this.dataNascitaField.errors.push(this.errors.NascitaData);
      } else {
        this.dataNascitaField.value = this.formValuesPreviousStep.NascitaData;
      }
      if (this.errors.NascitaComune != "") {
        this.comuneNascitaField.errors.push(this.errors.NascitaComune);
      } else {
        this.comuneNascitaField.value =
          this.formValuesPreviousStep.NascitaComune;
      }
      if (this.errors.Nazionalita != "") {
        this.nazionalitaField.errors.push(this.errors.Nazionalita);
      } else {
        this.nazionalitaField.value = this.formValuesPreviousStep.Nazionalita;
      }

      if (this.errors.Sesso != "") {
        this.sessoField.errors.push(this.errors.Sesso);
      } else {
        this.sessoField.value = this.formValuesPreviousStep.Sesso;
      }
    },
    handleStepOne(formValues) {
      this.$emit("stepOne", formValues);
    },
  },
};
</script>

<style>
.el-select {
  width: 100%;
}
</style>